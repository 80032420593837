// import React, { useState, useEffect } from 'react';
// import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
// import { Chart, LineController,CategoryScale, LineElement, PointElement, LinearScale, Title } from 'chart.js';

// Chart.register(LinearScale, PointElement, LineController, Title, CategoryScale);

const PercentageTable = () => {
  return <></>;
  //   const [data, setData] = useState({
  //     "_id": "your_document_id",
  //     "gameInstance": "your_document_id",
  //     "game": "your_document_id",
  //     "percentage": [
  //       {
  //         "timestamp": "2023-09-06T08:35:56.481+00:00",
  //         "value": "1.0045"
  //       },
  //       {
  //         "timestamp": "2023-09-06T08:36:56.481+00:00",
  //         "value": "2.882"
  //       },
  //       {
  //         "timestamp": "2023-09-06T08:37:56.481+00:00",
  //         "value": "3.141"
  //       }
  //     ]
  //   });
  //   useEffect(() => {
  //     if (data) {
  //       const timestamps = data.percentage.map(item => item.timestamp);
  //       const values = data.percentage.map(item => parseFloat(item.value));
  //       const ctx = document.getElementById('percentageChart').getContext('2d');
  //       new Chart(ctx, {
  //         type: 'line',
  //         data: {
  //           labels: timestamps,
  //           datasets: [
  //             {
  //               label: 'Percentage Value',
  //               data: values,
  //               borderColor: 'rgba(75, 192, 192, 1)',
  //               borderWidth: 1,
  //             },
  //           ],
  //         },
  //         options: {
  //           scales: {
  //             x: {
  //               type: 'category',
  //               time: {
  //                 unit: 'minute',
  //               },
  //             },
  //             y: {
  //               beginAtZero: true,
  //             },
  //           },
  //         },
  //       });
  //     }
  //   }, [data]);
  //   const options = {
  //     scales: {
  //       x: {
  //         type: 'time',
  //         time: {
  //           unit: 'minute',
  //         },
  //       },
  //       y: {
  //         beginAtZero: true,
  //       },
  //     },
  //   };
  //   return (
  //     <TableContainer component={Paper}>
  //       <Table>
  //         <TableHead>
  //           <TableRow>
  //             <TableCell>Game</TableCell>
  //             <TableCell>Game Instance</TableCell>
  //             <TableCell>Percentage</TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           <TableRow>
  //             <TableCell>{data.game}</TableCell>
  //             <TableCell>{data.gameInstance}</TableCell>
  //             <TableCell>
  //               <canvas id="percentageChart" width="400" height="200"></canvas>
  //             </TableCell>
  //           </TableRow>
  //         </TableBody>
  //       </Table>
  //     </TableContainer>
  //   );
};

export default PercentageTable;
