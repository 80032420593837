import { Box } from "@mui/material";
import React from "react";

const GameDuration = ({ endTime, startTime, row }) => {
  const calculateDuration = (endDate, startDate) => {
    const durations = new Date(endDate) - new Date(startDate);
    // const hours = Math.floor(durations / (1000 * 60 * 60));
    const minutes = Math.floor(durations / (1000 * 60));

    if (minutes <= 0) {
      return `${row?.game?.gameDuration ?? 0} ${
        row?.game?.gameFrequency ?? ""
      }`;
    }

    return `${minutes} Minutes`;
  };

  return (
    <>
      {" "}
      <Box
        sx={{
          height: 35,
          width: "120px",
          paddingX: 1,
          borderRadius: "20px",
          background:
            "linear-gradient(110deg, #11B1F4 -32.74%, #085A7D 127.35%)",
          textTransform: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
        }}
      >
        {calculateDuration(endTime, startTime)}
      </Box>
    </>
  );
};

export default GameDuration;
