import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import ProgressBar from "@ramonak/react-progress-bar";
import { useEffect, useState } from "react";
import { ambassadorReferral } from "../../../Services/Service";
const ambassador = [
  {
    img: "/Images/profile/referral_1.png",
    level: 1,
  },
  {
    img: "/Images/profile/referral_2.png",
    level: 2,
  },
  {
    img: "/Images/profile/referral_3.png",
    level: 3,
  },
];

const AmbassadorPrg = ({ ambReferral }) => {
  const [referral, setReferral] = useState(null);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("md"));

  // const isBigger

  useEffect(() => {
    if (ambReferral) {
      setReferral(ambReferral);
    } else {
      fetchAmbassadorReferral();
    }
  }, [ambReferral]);

  const fetchAmbassadorReferral = async () => {
    try {
      const userId = sessionStorage.getItem("userId");
      const response = await ambassadorReferral(userId);
      const referral = response.data;
      setReferral(referral?.data);
      console.log(`Ambassador Program IN : ${JSON.stringify(referral?.data)}`);
    } catch (error) {
      console.error(error);
    }
  };

  const getTitle = (level) => {
    switch (level) {
      case 1:
        return `Refer ${referral?.config?.aReferralCount ?? "0"} Friends`;
      case 2:
        return `Each Spends $${referral?.config?.aMinSpentAmount ?? "0"}`;
      case 3:
        return `${referral?.config?.aMinPctAmount ?? "0"}% Commission`;
      default:
        return "";
    }
  };

  const getCompleted = (level) => {
    switch (level) {
      case 1:
        return referral?.totalReferralCount ?? 0;
      case 2:
        return referral?.level2?.totalProfile ?? 0;
      case 3:
        return referral?.level3?.percentAmount ?? 0;
      default:
        return 0;
    }
  };

  const getMaxCompleted = (level) => {
    switch (level) {
      case 1:
        return referral?.config?.aReferralCount ?? 0;
      case 2:
        return referral?.config?.aReferralCount ?? 0;
      case 3:
        return null;
      default:
        return 0;
    }
  };

  const getCustomLabel = (level) => {
    switch (level) {
      case 1:
        if (
          (referral?.totalReferralCount ?? 0) >=
          (referral?.config?.aReferralCount ?? 0)
        ) {
          return "Done";
        } else {
          return `${referral?.totalReferralCount ?? 0.0}/${
            referral?.config?.aReferralCount ?? 0
          }`;
        }

      case 2:
        if (
          (referral?.level2?.totalProfile ?? 0) >=
          referral?.config?.aReferralCount
        ) {
          return "Done";
        } else {
          if (!referral?.level2?.isEligible) {
            return " ";
          }
          return `${referral?.level2?.totalProfile ?? 0}/${
            referral?.config?.aReferralCount ?? 0
          }`;
        }

      case 3:
        if (!referral?.level3?.isEligible) {
          return " ";
        }
        return `${parseFloat(
          Number(referral?.level3?.percentAmount ?? 0).toFixed(5)
        )}`;
      default:
        return "";
    }
  };

  const getIsLabelVisible = (level) => {
    switch (level) {
      case 1:
        return true;
      case 2:
        return referral?.level2?.isEligible ?? false;
      case 3:
        return referral?.level3?.isEligible ?? true;
      default:
        return false;
    }
  };

  return (
    <Stack
      alignContent={"center"}
      marginBottom={2}
      marginLeft={mobile ? "" : 2}
      width={"100%"}
      height={"100%"}
      sx={{
        maxWidth: {
          sm: "80%",
          md: "66%",
          xs: "60%",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          // display: "inline-block",
          width: "fit-content",
          direction: "row",
          justifyContent: "center",
          alignItems: "end", // Center the content horizontally
          textAlign: "center", // Center the text inside Typography
          alignContent: "end",
          whiteSpace: "nowrap",
          // mx: 3,
          // borderRadius: 10,
          overflowX: "auto",
          gap: mobile ? 4.5 : 6,
          ml: 2,
        }}
      >
        {ambassador.map((e, index) => (
          <Box
            key={index}
            sx={{
              display: "inline-block",
              // my: 1,
              // mx: 2,
            }}
          >
            <img
              src={e?.img}
              alt=""
              style={{
                width: mobile ? 50 : null,
              }}
            />
            <Typography
              sx={{
                fontSize: mobile ? 8 : 13,
              }}
            >
              {getTitle(e.level)}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "inline-block",
          width: "fit-content",
          direction: "row",
          justifyContent: "center",
          alignItems: "center", // Center the content horizontally
          textAlign: "center", // Center the text inside Typography
          alignContent: "end",
          whiteSpace: "nowrap",
          background: "#011f2d",
          my: 1,
          // mx: 3,
          borderRadius: 10,
          overflowX: "auto",
        }}
      >
        {ambassador.map((e, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "inline-block",
                my: 1,
                mx: 1,
              }}
            >
              {e.level === 50000000 ? (
                <Box
                  sx={{
                    width: mobile ? "5rem" : "8.0rem",
                    height: mobile ? "1rem" : "1.4rem",
                    backgroundColor: "#085A7D",
                    overflow: "hidden",
                    borderRadius: 10,
                    alignContent: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: mobile ? 10 : 12,
                      margin: 0,
                      padding: 0,
                      lineHeight: 1,
                      textAlign: "inherit",
                      overflow: "hidden",
                    }}
                  >
                    {parseFloat(getCompleted(e?.level).toFixed(5))}
                  </Typography>
                </Box>
              ) : (
                <ProgressBar
                  completed={getCompleted(e?.level)}
                  maxCompleted={getMaxCompleted(e?.level) ?? 100}
                  customLabel={getCustomLabel(e?.level)}
                  // isLabelVisible={getIsLabelVisible(e?.level)}

                  isLabelVisible={true}
                  customLabelStyles={{
                    color: "white",
                    // paddingLeft: e?.level === 3 ? "5rem" : "",
                    // paddingRight: e?.level === 3 ? "5rem" : "",
                    // width: e?.level === 3 ? "100%" : null,
                  }}
                  // labelColor=""
                  // width="140px"
                  width={mobile ? "5rem" : "8.0rem"}
                  // height="20px"
                  height={mobile ? "1rem" : "1.4rem"}
                  labelSize={mobile ? "10px" : "12px"}
                  // padding="5px"
                  // labelAlignment="left"
                  labelAlignment={
                    getCustomLabel(e?.level) == "Done" ? "center" : "left"
                  }
                  // baseBgColor={"#011f2d"}
                  baseBgColor={"#085A7D"}
                  bgColor={
                    getCustomLabel(e?.level) == "Done"
                      ? "#2DB969"
                      : // : "linear-gradient(109.59deg, #11b1f4, #085a7d)"
                        "linear-gradient(109.59deg, #11B1F4 -32.74%, #085A7D 127.35%)"
                  }
                />
              )}
            </Box>
          );
        })}
      </Box>
    </Stack>
  );
};

export default AmbassadorPrg;
