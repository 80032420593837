import MagicPassport from "magic-passport";

const options = {
  network: {
    rpcUrls: {
      default: {
        http: ["https://rpc2.sepolia.org"],
      },
    },
    id: 11_155_111,
  },
  debug: false, //set to true for additional logging
};

export const magicPassport = new MagicPassport(
  // process.env.REACT_APP_MAGIC_PUBLISHABLE_API_KEY,
  "pk_3F1A1FD72AA54399",
  // options
);
