import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { getTwoRecentWinners } from "../../Services/Service";
import { champsCovertDecimals } from "../../Utils/token";
import { commonData } from "../../constants/common";

const WinnersUpdate = () => {
  // const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchRecentWinners = async () => {
    try {
      // await delay(400);
      const response = await getTwoRecentWinners();
      console.log(response.data);
      return response.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: ["recentWinners"],
    queryFn: fetchRecentWinners,
    // cacheTime: 10 * 1000,
    enabled: true,
    refetchOnWindowFocus: false,
  });

  console.log(data, "recentWinners");
  return (
    <Box
      sx={{
        // width: "360px",
        height: "260px",
        // background: "linear-gradient(180deg, #002839 0%, #001D2A 76.5%)",
        background: "#002839",
        marginTop: 4,
        padding: 3,
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        // overflowY: "auto",
      }}
      gap={4}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" fontWeight={500} fontSize={20}>
          Winners Updates
        </Typography>
        {/* <MoreVertIcon
          sx={{
            color: "#AFB4C0",
          }}
        /> */}
      </Box>

      {isLoading ? (
        <>
          <>
            <Box sx={{ width: "100%", height: "100%" }}>
              <Skeleton animation="wave" height={80} />
              <Skeleton animation="wave" height={80} />
            </Box>
          </>
        </>
      ) : (
        <>
          {data.length === 0 ? (
            <>
              <Typography textAlign={"center"} marginTop={4}>
                There is no winners{" "}
              </Typography>{" "}
            </>
          ) : (
            data?.map((user, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                // key={index}
              >
                <Box display={"flex"} alignItems={"center"} gap={1}>
                  <Avatar
                    alt="Remy Sharp"
                    sx={{
                      width: 50,
                      height: 50,
                    }}
                    src={user[0]?.userId?.profilePic}
                  />
                  <Box>
                    <Typography
                      variant="caption"
                      fontSize={16}
                      fontWeight={500}
                    >
                      {user[0]?.userId?.username}
                    </Typography>
                  </Box>
                </Box>
                <Typography fontSize={{ xs: 10, sm: 12, md: 14 }}>
                  {/* {user[0]?.prizeAmount?.toFixed(2)} USDC */}
                  {champsCovertDecimals(
                    user[0]?.prizeAmount,
                    commonData?.tokenDecimal
                  )}{" "}
                  USDC
                </Typography>
              </Box>
            ))
          )}
        </>
      )}
    </Box>
  );
};

export default WinnersUpdate;
