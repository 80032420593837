import React, { useEffect, useState, useRef } from "react";

const ImageSwitcher = ({ images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const imgRef = useRef(null); // Reference to the image element

  // Preload all images to improve switch performance
  useEffect(() => {
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  }, [images]);

  const handleImageLoad = () => {
    setIsLoaded(true); // Set the state when the image is fully loaded
  };

  const switchImage = (index) => {
    setIsLoaded(false); // Reset loading state
    setCurrentImage(index);
  };

  return (
    <div style={{ position: "relative", width: "500px", height: "300px" }}>
      <img
        ref={imgRef}
        src={images[currentImage]}
        alt={`image-${currentImage}`}
        onLoad={handleImageLoad}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          transition: "opacity 0.5s ease-in-out",
          opacity: isLoaded ? 1 : 0,
        }}
        loading="lazy" // Enable lazy loading
      />
      <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
        {images.map((_, index) => (
          <button
            key={index}
            onClick={() => switchImage(index)}
            style={{
              padding: "5px 10px",
              background: currentImage === index ? "#4caf50" : "#ccc",
              border: "none",
              borderRadius: "4px",
            }}
          >
            Image {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImageSwitcher;
