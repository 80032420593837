import { useTheme } from "@emotion/react";
import {
  Avatar,
  Box,
  Tooltip,
  styled,
  tooltipClasses,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import CryptoTooltip from "../Tooltip/LightToolTip";

// const LightTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.white,
//     color: "rgba(0, 0, 0, 0.87)",
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//     fontWeight: 500,
//   },
// }));

const HomeLeaderBoardTeam = ({ team }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [showFullCryptos, setShowFullCryptos] = useState(false);

  const handleShowAllCryptos = () => setShowFullCryptos((prev) => !prev);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"start"}
      gap={{ xs: 0.2, md: 0.5 }}
    >
      <Box display={"flex"} gap={isMobile ? 0.5 : 1} alignItems={"center"}>
        {team.slice(0, 4).map((crypto, index) => (
          <div key={index}>
            <CryptoTooltip
              disableFocusListener
              disableTouchListener
              title={crypto?.name}
              placement="top"
              slotProps={{
                popper: {
                  sx: {
                    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                      {
                        marginBottom: "5px",
                      },
                  },
                },
              }}
            >
              <Avatar
                sx={{
                  width: isMobile ? 12 : 22,
                  height: isMobile ? 12 : 22,
                  border: "1px solid white",
                  padding: 0.3,
                }}
                src={crypto?.image}
              />
            </CryptoTooltip>
          </div>
        ))}

        {team?.length > 4 && (
          <Box
            // className="avatar"
            sx={{
              width: isMobile ? 10 : 22,
              height: isMobile ? 10 : 22,
              borderRadius: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontWeight: 600,
              fontSize: { xs: 10, md: 16 },
              color: "white",
              "&:hover": {
                cursor: "pointer",
                borderBottom: "1px solid #11B1F4",
              },
              padding: 1,
              background:
                "linear-gradient(to right, #11B1F4 10%, #085A7D  100%, #085A7D 100%)",
            }}
            onClick={handleShowAllCryptos}
          >
            {showFullCryptos ? "-" : "+"}
          </Box>
        )}
      </Box>
      <Box display={"flex"} gap={isMobile ? 0.5 : 1} alignItems={"center"}>
        {team?.slice(4, 100).map((crypto, index) => (
          <Avatar
            key={index}
            // className="avatar"
            sx={{
              display: showFullCryptos ? "flex" : "none",
              width: isMobile ? 12 : 22,
              height: isMobile ? 12 : 22,
              border: "1px solid white",
              padding: 0.3,
            }}
            src={crypto?.image}
          />
        ))}
      </Box>
    </Box>
  );
};

export default HomeLeaderBoardTeam;
